<template>
  <div class="repair-detail wrapper">
    <a-spin :spinning="loading">
      <a-row
        type="flex"
        justify="space-between"
        style="padding: 0 20px"
        v-if="repair"
      >
        <a-col>
          <div class="flex flex-between info">
            <div class="info-title">上报类型</div>
            <div class="info-content">{{ repair.repair_type_name }}</div>
          </div>
        </a-col>
        <a-col>
          <div class="flex flex-between info">
            <div class="info-title">房间/位置</div>
            <div class="info-content">
              {{ repair.repair_address }}
            </div>
          </div>
        </a-col>
        <a-col>
          <div class="flex flex-between info">
            <div class="info-title">联系人</div>
            <div class="info-content">{{ repair.repair_contact_name }}</div>
          </div>
        </a-col>
        <a-col>
          <div class="flex flex-between info">
            <div class="info-title">联系电话</div>
            <div class="info-content">{{ repair.repair_contact_phone }}</div>
          </div>
        </a-col>
        <a-col :span="24">
          <div class="text-blak font-18 mt-20 mb-20">
            {{ repair.repair_text }}
          </div>
        </a-col>
        <a-col :span="24">
          <div class="flex show-box mt-20" v-if="repairImg">
            <a-image-preview-group>
              <div
                class="img-item"
                v-for="(item, index) in repairImg"
                :key="index"
              >
                <a-image
                  :width="140"
                  :height="140"
                  :src="item"
                  class="repair-img"
                />
              </div>
            </a-image-preview-group>
          </div>
        </a-col>
        <a-col :span="24">
          <div class="line"></div>
        </a-col>
        <a-col :span="24">
          <div class="flex flex-between font-18 status-bar">
            <span class="text-black">上报状态</span>
            <div
              :class="{
                'repair-status': true,
                'label-gray': repair.repair_status == -1,
                'label-red': repair.repair_status == 0,
                'label-green': repair.repair_status == 1
              }"
            >
              {{ transferStatusName(repair.repair_status) }}
            </div>
          </div>
        </a-col>
        <a-col :span="24">
          <div class="step">
            <a-steps direction="vertical" :current="repair.repair_status">
              <a-step
                title="等待处理"
                :description="repair.repair_create_time"
              />
              <a-step
                v-if="repair.repair_cancel_time"
                title="已取消"
                :description="repair.repair_cancel_time"
              />
              <a-step v-if="!repair.repair_cancel_time" title="处理中" :description="repair.repair_accept_time" />
              <a-step v-if="!repair.repair_cancel_time" title="已完成" :description="repair.repair_finish_time" />
            </a-steps>
          </div>
        </a-col>
        <a-col :span="24" v-if="repair.repair_status == 0">
          <a-button
            block
            size="large"
            type="primary"
            class="btn"
            :loading="loadingBtn"
            @click="updateRepairCancelStatusDataFun"
          >
            取消上报
          </a-button>
        </a-col>
      </a-row>
    </a-spin>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  reactive,
  toRefs,
  watch
} from "@vue/runtime-core";
import { message } from "ant-design-vue";
import { getUserId } from "@/utils/storeData.js";
import { getRepairData, updateRepairCancelStatusData } from "@/api/main.js";
import { encode, decode } from "js-base64";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  name: "RepairDetail",
  components: {},
  setup() {
    const route = useRoute();
    const pageData = reactive({
      loading: false,
      user_id: computed(() => getUserId()),
      repair: null,
      repairId: null,
      repairImg: [],
      loadingBtn: false
    });
    const getRepairDataFun = _repair_id => {
      pageData.loading = true;
      getRepairData({
        user_id: pageData.user_id,
        repair_id: _repair_id
      })
        .then(res => {
          if (res.code == 200) {
            pageData.repair = res.data.repair;
            if (res.data.repair.repair_image) {
              pageData.repairImg = res.data.repair.repair_image.split(",");
            }
          } else {
            message.error(res.msg);
          }
        })
        .catch(res => {
          console.log(res);
        })
        .finally(() => {
          pageData.loading = false;
        });
    };
    const updateRepairCancelStatusDataFun = () => {
      pageData.loadingBtn = true;
      pageData.loading = true;
      updateRepairCancelStatusData({
        repair_id: pageData.repairId,
        user_id: pageData.user_id
      })
        .then(res => {
          if (res.code == 200) {
            getRepairDataFun(pageData.repairId);
          } else {
            message.error(res.msg);
          }
        })
        .catch(res => {
          console.log(res);
        })
        .finally(() => {
          pageData.loading = false;
          pageData.loadingBtn = false;
        });
    };
    const transferStatusName = _status => {
      let statusName = ["取消报修", "等待处理", "处理中", "已完成"];
      return statusName[_status + 1];
    };
    watch(
      () => route.query,
      val => {
        if (route.path == "/repair/detail") {
          let query = decode(val?.t).split(",");
          if (query[0]) {
            pageData.loading = true;
            pageData.repairId = query[0];
            getRepairDataFun(query[0]);
          } else {
            pageData.loading = false;
            pageData.repair = null;
            message.error("暂无报修信息");
          }
        }
      },
      {
        immediate: true,
        deep: true
      }
    );
    return {
      ...toRefs(pageData),
      transferStatusName,
      updateRepairCancelStatusDataFun
    };
  }
});
</script>
<style lang="less">
.repair-detail {
  padding: 40px 0;
  .info {
    width: 540px;
    border-bottom: 1px solid #dfdfdf;
    height: 60px;
    .info-title {
      color: #212531;
      opacity: 0.7;
      font-size: 18px;
    }
    .info-content {
      color: #212531;
      font-size: 18px;
    }
  }
  .img-item {
    margin-right: 10px;
    width: 140px;
    height: 140px;
    overflow: hidden;
    .repair-img {
      display: block;
      width: 140px;
      height: 140px;
      background-color: #212531;
    }
  }
  .line {
    height: 10px;
    background-color: #f3f5f8;
    margin-top: 50px;
    margin-bottom: 20px;
  }
  .status-bar {
    padding-bottom: 20px;
    border-bottom: 1px solid #dfdfdf;
  }
  .step {
    margin-top: 30px;
    padding: 0 50px;
    .ant-steps-item-title {
      font-size: 20px;
    }
  }
}
</style>
